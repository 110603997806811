// extracted by mini-css-extract-plugin
export var arrowButton = "vacancy-module--arrow-button--3c7e8";
export var breadcrumbs = "vacancy-module--breadcrumbs--7458a";
export var form = "vacancy-module--form--02c6c";
export var formSubmit = "vacancy-module--form-submit--6bcbf";
export var heading = "vacancy-module--heading--7f2a1";
export var headingContent = "vacancy-module--heading-content--0a143";
export var icon = "vacancy-module--icon--3718b";
export var iconBlock = "vacancy-module--icon-block--9b999";
export var inputLabel = "vacancy-module--input-label--28ac5";
export var jobContent = "vacancy-module--job-content--c3b68";
export var typeform = "vacancy-module--typeform--64066";