import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import * as s from "../assets/css/vacancy.module.css";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";

import Layout from "../components/layout";
import Seo from "../components/seo";
import VacancySidebar from "../components/VacancySidebar/VacancySidebar";
import schemaBuilder from "../utils/schemaBuilder";

const VacancyTemplate = ({ data: { vacancy, site, wp }, location }) => {
  // const featuredImage = {
  //   data: vacancy.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  //   alt: vacancy.featuredImage?.node?.alt || ``,
  // }

  const [applyLinkUrl, setApplyLinkUrl] = useState(vacancy?.acf_vacancies?.applyLink?.link?.url)


  useEffect(() => {

    const queryString = window?.location?.search;
    const urlParams = new URLSearchParams(queryString);

    if (!!applyLinkUrl) {
      let applyLinkObj = new URL(applyLinkUrl);

      const utm_source = urlParams.get('utm_source');
      const utm_medium = urlParams.get('utm_medium');
      const utm_campaign = urlParams.get('utm_campaign');
      const utm_content = urlParams.get('utm_content');
      const utm_term = urlParams.get('utm_term');

      applyLinkObj.searchParams.set('utm_source', utm_source);
      applyLinkObj.searchParams.set('utm_medium', utm_medium);
      applyLinkObj.searchParams.set('utm_campaign', utm_campaign);
      applyLinkObj.searchParams.set('utm_content', utm_content);
      applyLinkObj.searchParams.set('utm_term', utm_term);
      applyLinkObj.hash = `#vacancy_name=${vacancy.title.replaceAll(' ', '+')}`;

      setApplyLinkUrl(applyLinkObj.href);
    }

  }, [])

  console.log(wp);

  const logo = {
    data: vacancy?.acf_vacancies?.cardBlock?.logo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: vacancy?.acf_vacancies?.cardBlock?.logo?.altText || ``,
  };

  let blockStyles = {};
  if (!!vacancy?.acf_vacancies?.cardBlock?.color) {
    blockStyles["--single-job-color"] = vacancy?.acf_vacancies?.cardBlock?.color;
  }


  // let initialQuizData = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   message: '',
  // };


  // const [formData, setFormData] = React.useState(initialQuizData);
  // const [messageSent, setMessageSent] = React.useState(null);
  // const [errors, setErrors] = React.useState([]);

  // const handleInputChange = (event) => {
  //   const target = event.target;
  //   const name = target.name;
  //   let value = target.value;
  //   let newFormData = { ...formData };
  //   newFormData[name] = value;
  //   setFormData(newFormData);
  // }

  // const submitForm = () => {
  //   if (isValidForm()) {
  //     setMessageSent(true);


  //     let payload = {
  //       event: 'formSubmit',
  //       type: 'vacancy-page',
  //       ...formData
  //     };
  //     // console.log(payload);
  //     window.dataLayer.push({ ...payload });

  //     const requestOptions = {
  //       method: "POST",
  //       mode: "no-cors",
  //       headers: {
  //         "Content-Type": "application/json",
  //         'Accept': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //       body: JSON.stringify(payload),
  //     };
  //     try {
  //       fetch("https://hooks.zapier.com/hooks/catch/6831474/b0d194c/", requestOptions);
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }

  // const isValidForm = () => {
  //   let isValid = true;
  //   let newErrors = [];

  //   if (!formData?.firstName?.length) {
  //     isValid = false;
  //     newErrors.push('Please enter a First Name');
  //   }

  //   if (!formData?.lastName?.length) {
  //     isValid = false;
  //     newErrors.push('Please enter a Last Name');
  //   }

  //   if (!isEmailValid(formData?.email)) {
  //     isValid = false;
  //     newErrors.push('Please enter a valid Email');
  //   }



  //   if (!formData?.message?.length) {
  //     isValid = false;
  //     newErrors.push('Please enter a Message');
  //   }


  //   setErrors(newErrors);
  //   // isValid = false;
  //   return isValid;
  // }

  // const isEmailValid = (email) => {
  //   const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   return re.test(String(email).toLowerCase());
  // }

  const schema = schemaBuilder({ vacancy: vacancy }, 'vacancy', site);

  return (
    <Layout pageTitle={`Vacancy - ${vacancy.title}`}>
      <Seo
        title={vacancy.title}
        description={""}
        pageSeo={vacancy}
        location={location}
        schema={schema}
      />
      <section className={s.heading} style={blockStyles}>
        <div className="container">
          <div className={`${s.headingContent}`}>
            <div className={`${s.iconBlock}`}>
              {!!logo.data &&
                <GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={logo.data}
                  alt={logo.alt}
                  width={58}
                  height={58}
                  className={`${s.icon}`} />
              }
            </div>
            <h1 className="flex-grow-1">{parse(vacancy.title)}</h1>
            {!!applyLinkUrl &&
              <Link to={applyLinkUrl} target="_blank" className={`${s.arrowButton}`}>
                {!!vacancy?.acf_vacancies?.applyLink?.link?.title ? vacancy?.acf_vacancies?.applyLink?.link?.title : 'Apply'} &#10132;
              </Link>
            }
          </div>
        </div>
      </section>
      <section className={`${s.jobContent} privacy-policy`} style={blockStyles}>
        <div className="container">
          <div className="row" style={{ columnGap: '46px' }}>
            <div className="col-12 order-1">
              <div className={s.breadcrumbs}>
                <Link to={"/job-openings/"}>Job Openings</Link>
                <span> / </span>
                <span>{parse(vacancy.title)}</span>
              </div>
            </div>
            <div className="col-xl-4 order-2 order-xl-3 d-flex">
              <VacancySidebar data={wp?.acfOptionsVacanciesSidebar?.vacancies_sidebar?.vacanciesSidebar} />
            </div>
            <div className="col  order-3 order-xl-2">
              {!!vacancy.content &&
                parse(vacancy.content)
              }
              {!!applyLinkUrl &&
                <div className="mt-5">
                  <Link to={applyLinkUrl} target="_blank" className={`${s.arrowButton}`}>
                    {!!vacancy?.acf_vacancies?.applyLink?.link?.title ? vacancy?.acf_vacancies?.applyLink?.link?.title : 'Apply'} &#10132;
                  </Link>
                </div>
              }
            </div>
          </div>

        </div>
      </section>
      {/* <section className={`${s.form} d-none`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-ls-8 col-xl-8 col-xxl-6 d-none">
              {!messageSent &&
                <div className="row">
                  <div className="col-12">
                    <h2>Apply Form</h2>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={`${s.inputLabel}`}>
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        placeholder="Enter your first name" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={`${s.inputLabel}`}>
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        placeholder="Enter your last name" />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className={`${s.inputLabel} w-100`}>
                      <label htmlFor="email">Your Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={`${s.inputLabel}`}>
                      <label htmlFor="comp-name">Message</label>
                      <textarea
                        name={'message'}
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Enter your message" />

                    </div>
                  </div>
                  <div className="col-12">
                    <button className={`${s.formSubmit} `} onClick={submitForm}>Send</button>
                  </div>
                  <div className="col-12">
                    {errors?.length > 0 &&
                      <div className={`mt-3 d-flex flex-column`}
                        style={{ 'gap': '4px', 'maxWidth': 'max-content' }}>
                        {errors?.map((err, erri) => {
                          return <div className={`error-message`} key={`error-${erri}`}>{err}</div>
                        })}
                      </div>
                    }
                  </div>
                </div>
              }
              {messageSent && <ThankYou />}
            </div>
            <div className="col-12 col-xxl-6">
              <h2>Apply Form</h2>
              <Widget hidden={{ vacancy_name: vacancy.title }}
                id={process.env.TYPEFORM_FORM_ID}
                className={`${s.typeform} my-form`} />
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  )
}

export default VacancyTemplate

export const vacancyQuery = graphql`
  query VacancyById(
    $id: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    vacancy: wpVacancy(id: { eq: $id }) {
      id
      content
      title
      uri
      date(formatString: "MMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_vacancies {
        schemaBlock {
          employmentType
          validThrough
          minSalary
          maxSalary
        }
        applyLink {
          link {
            url
            title
            target
          }
        }
        cardBlock {
          color
          description
          title
          logo {
            mediaItemUrl
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
            altText
          }
          hoverLogo {
            mediaItemUrl
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
            altText
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    wp {
      acfOptionsVacanciesSidebar {
        vacancies_sidebar {
          vacanciesSidebar {
            title
            content
            image {
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
          }
        }
      }
    }
  }
`
