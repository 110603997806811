import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./VacancySidebar.module.css";

const VacancySidebar = ({ data }) => {
  const bp = useBreakpoint();

  const image = {
    data: data.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.image?.altText || ``,
  };

  const [opened, setOpened] = React.useState(false);

  return (
    <div className={`${s.vacancySidebar}`}>
      {data.title && <h2>{parse(data.title)}</h2>}
      {data.content && <div className={`${s.content} ${opened ? s.opened : ''}`}>
        {parse(data.content)}

      </div>}
      {!!image.data &&
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={image.data}
          alt={image.alt}
          className={`${s.icon}`} />
      }
      {(!bp.xl && !opened) && <div className={s.learnMore}>
        <button onClick={() => { setOpened(true) }}>Learn More</button>
      </div>}
    </div>
  )
}

export default VacancySidebar
